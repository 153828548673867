
  import { defineComponent, ref, onBeforeMount } from "vue";
  import { apiGet, apiPut } from "@/api";
  import DualRingLoader from "@/components/loader/DualRingLoader.vue";
  import { Toast } from "@/utils/notify";
  import InputWrapper from "@/components/InputWrapper.vue";
  
  export default defineComponent({
    name: "GHAccountForm",
    components: { InputWrapper, DualRingLoader },
    emits: ["save"],
    setup() {
      const formData = ref({
        account_name: null,
        account_number: null,
        bank_name: null,
        id: null
      });
      const formError = ref({});
      const loading = ref(false);
      const loaded = ref(false);
      async function Submit() {
        loading.value = true;
        formError.value = {};
        try {
          await apiPut(`/admin/misc/ghana/account/${formData.value.id}`, {
            account_name: formData.value.account_name,
            account_number: formData.value.account_number,
            bank_name: formData.value.bank_name,
          });
          Toast.fire({
          icon: "success",
          title: "Account information updated successfully",
        }).then();
        } catch (e:any) {
          formError.value = e?.response?.data?.i ?? {};
          Toast.fire({
            icon: "warning",
            title: "Unable to update account information: " + e.message,
          }).then();
        }
        loading.value = false;
      }

      const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(`/admin/misc/ghana/account`);
        const val = response.data.data.ghanaBankAccountResponses;
        console.log(val);
        formData.value = {
            account_name: val[0].account_name,
            account_number: val[0].account_number,
            bank_name: val[0].bank_name,
            id: val[0].id,
        };
        console.log(formData.value);
        loaded.value = true;
        Toast.fire({
          icon: "success",
          title: "Response fetched",
        }).then();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };
    onBeforeMount(RefreshData);
      return {
        Submit,
        RefreshData,
        loaded,
        formData,
        formError,
        loading,
      };
    },
  });
  