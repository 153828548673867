
  import { defineComponent } from "vue";
  import GHAccountForm from "@/views/settings/constituent/GHAccountForm.vue";
  
  export default defineComponent({
    name: "GHAccountSettings",
    components: {
        GHAccountForm,
    },
    setup() {
      return {};
    },
  });
  