import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["input-group", _ctx.errors ? 'is-invalid' : ''])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    (_ctx.errors && _ctx.errors.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.errors, (error, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "invalid-feedback"
          }, _toDisplayString(error), 1))
        }), 128))
      : _createCommentVNode("", true)
  ], 64))
}